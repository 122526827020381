// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Helvetica', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$aspect-ratios: (
  "1": 100%,
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "9x21": calc(21 / 9 * 100%)
);
