[v-cloak] {
  display: none;
}

body {
  //font-size: 1rem !important;
}

a {
  text-decoration: none!important;
}

.btn-danger, .btn-success, .btn-primary {
  color: #fff;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important
}

.pl-1, .px-1 {
  padding-left: .25rem !important
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important
}

.pr-1, .px-1 {
  margin-right: .25rem !important
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important
}

.ml-1, .mx-1 {
  margin-left: .25rem !important
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important
}

.mr-1, .mx-1 {
  margin-right: .25rem !important
}


@media (min-width: 1200px) {
  .container-large {
    max-width: 1720px !important;
  }
}

.modal-3x4 {
  max-width: 660px !important;
}

.modal-9x16 {
  max-width: 490px !important;
}

.modal-4x3 {
  max-width: 1160px !important;
}

.modal-16x9 {
  max-width: 1280px !important;
}

.modal-square {
  max-width: 880px !important;
}

.modal-native {
  max-width: 660px !important;
}

.setting-header-background {
  /*box-shadow: 0 12px 39px -19px rgba(0, 0, 0, 0.75);*/
  border: 0;
  background-size: cover;
  border-radius: 0;
}

.ql-align-center {
  text-align: center;
}

.custom-bullet ul {
  list-style-type: none;
}

.carousel-inner {
  border-radius: 0.25rem;
}

.cursor-alias {
  cursor: alias;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-auto {
  cursor: auto;
}

.cursor-cell {
  cursor: cell;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-copy {
  cursor: copy;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-text {
  cursor: text;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-wait {
  cursor: wait;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}
